.root {
  @apply relative w-full bg-black h-[50px] px-[15px] flex justify-between items-center text-white py-2 px-4;
  transform: translate(-3px, -3px);
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  touch-action: manipulation;
  transition: 300ms cubic-bezier(0.3, 0, 0, 1);
  transition-property: color, transform;

  &:hover {
    color: #767677;
    svg {
      color: #767677;
    }
  }

  &:active {
    transform: translate(0, 0);
    &:before {
      transform: translate(-3px, -3px);
    }
    &:after {
      transform: translate(-3px, -3px);
    }
  }

  &::before, &::after {
    content: "";
    display: block;
    position: absolute;
    transition: transform 300ms cubic-bezier(0.3, 0, 0, 1);
  }

  &::before {
    border-bottom: 1px solid black;
    border-left: 1px solid black;
    bottom: -3px;
    height: 3px;
    left: 3px;
    width: 100%;
  }

  &::after {
    border-right: 1px solid black;
    border-top: 1px solid black;
    height: 100%;
    right: -3px;
    top: 3px;
    width: 3px;
  }
}